import { graphql, Link } from "gatsby";
import React, { useMemo } from "react";
import RichText from "../../components/RichText";
import Layout from "../../containers/Layout";
import Section from "../../containers/Section";
import SEO from "../../components/Seo";
import EmbeddedCta from "../../components/Blog/EmbeddedCta";
import Header, { HeaderVariant } from "../../components/Header";
import Button, { ButtonSize, ButtonVariant } from "../../components/Button";
import Paragraph from "../../components/Paragraph";
import GradientSection from "../../containers/GradientSection";
import TreeLeft from "../../images/Resources/treeleft.svg";
import TreeRight from "../../images/Resources/treeright.svg";
import Slywheel from "../../images/slywheel.svg";
import cn from "classnames";
import { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import FormModal from "../../components/FormModal";
import { CTA_ID, FormType } from "../../utils/analytics/constants";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { BreadcrumbPageType, formatBreadcrumb } from "../../utils/breadcrumb";

interface ResourceTemplateProps {
  data: any;
  pageContext: any;
}

const ResourceTemplate: React.FC<ResourceTemplateProps> = props => {
  const { data, pageContext } = props;

  const [activeDownload, setActiveDownload] = useState<boolean>(false);

  const resource = data?.resource;
  const sharedPositionedClassnames =
    "absolute w-35 sm:w-56 -bottom-12 sm:bottom-0 lg:bottom-1/2 lg:translate-y-1/2 lg:w-60";

  const updatedCrumbs = useMemo(
    () => formatBreadcrumb(pageContext, BreadcrumbPageType.ResourcesSingle),
    []
  );

  return (
    <Layout inlineSwitcher>
      <SEO
        title={resource?.seoSettings?.metadataTitle || resource?.title}
        description={
          resource?.seoSettings?.metadataDescription ||
          resource?.bodyDescription
        }
        image={
          resource?.seoSettings?.openGraphImage?.url || resource?.image?.url
        }
        robots={resource?.seoSettings?.robots}
      />

      <Section>
        <div className="mt-40">
          <CustomBreadcrumb crumbs={updatedCrumbs} />
        </div>
        <div className="pb-56 bg-gradient-to-br from-blue-2-lighter to-blue-2 rounded-2xl overflow-hidden mt-10 pt-18 flex flex-col items-center">
          <p className="text-indigo-1 font-semibold">{resource?.subtitle}</p>
          <Header
            className="text-center max-w-2xl py-6"
            variant={HeaderVariant.h1}
          >
            {resource.title}
          </Header>
          <Button
            id={CTA_ID.resourcesPageHeader.id}
            size={ButtonSize.large}
            onClick={() => setActiveDownload(true)}
            variant={ButtonVariant.primary}
          >
            Download
          </Button>
        </div>
        <div className="h-[250px] max-w-xl mx-auto relative">
          <GatsbyImage
            alt=""
            className="absolute top-0 -translate-y-1/2 shadow-2xl rounded-2xl w-full"
            image={resource?.secondaryImage?.gatsbyImageData}
          />
          <div className="mx-auto shadow-2xl absolute w-full max-w-[250px] left-1/2 -translate-x-1/2 top-0 -translate-y-1/2 rounded-2xl">
            <GatsbyImage
              className="w-full rounded-2xl"
              image={resource?.image?.gatsbyImageData}
              alt=""
            />
          </div>
        </div>
      </Section>
      <Section>
        <div className="bg-gradient-to-br from-blue-3 py-22 rounded-2xl px-4">
          <div className="max-w-2xl px-6 mx-auto">
            <Header
              className="text-indigo-3-dark text-center mx-auto"
              variant={HeaderVariant.h2}
            >
              {resource?.bodyTitle}
            </Header>
            <Paragraph className="text-indigo-3-dark text-center pt-8 pb-18">
              {resource?.bodyDescription}
            </Paragraph>
            <div className="richtext-center">
              <RichText data={resource?.bodyMain} />
            </div>
          </div>
        </div>
      </Section>
      <GradientSection className="pt-32 relative min-h-[400px] flex justify-center items-center pb-60 lg:pb-0 lg:mb-36">
        <div className="max-w-sm">
          <Header
            className="text-center text-4xl max-w-[300px] mb-4"
            variant={HeaderVariant.h5}
          >
            You're already growing so why not grow more?
          </Header>
          <div className="w-[150px] mx-auto py-20 relative">
            <GatsbyImage
              alt=""
              className="relative z-20 mx-auto shadow-xl w-full"
              image={resource?.image?.gatsbyImageData}
            />
            <Slywheel className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-56 sm:w-72" />
          </div>
          <Button
            id={CTA_ID.resourcesPageBottom.id}
            className="mx-auto"
            size={ButtonSize.large}
            onClick={() => setActiveDownload(true)}
            variant={ButtonVariant.primary}
          >
            Download
          </Button>
        </div>
        <TreeLeft className={cn("left-0", sharedPositionedClassnames)} />
        <TreeRight className={cn("right-0", sharedPositionedClassnames)} />
      </GradientSection>
      <Section mobileWide className="px-0 sm:px-4 sm:py-12">
        <EmbeddedCta
          fullWidth
          buttonText="Sign Up"
          title="Insights to help grow profit"
          textFieldPlaceholder="Email address"
          successText="Thanks for signing up! We promise only the best updates and growth rocket fuel coming your way."
        />
      </Section>
      {activeDownload && (
        <FormModal
          formType={FormType.RESOURCE}
          data={resource}
          title={resource?.title}
          setActiveModal={setActiveDownload}
        />
      )}
    </Layout>
  );
};

export default ResourceTemplate;

export const query = graphql`
  query ResourceBySlug($slug: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    resource: contentfulResources(slug: { eq: $slug }) {
      bodyTitle
      bodyMain {
        raw
      }
      bodyDescription
      category
      resourceFile {
        file {
          url
        }
      }
      slug
      subtitle
      title
      id
      seoSettings {
        metadataDescription
        metadataTitle
        openGraphImage {
          url
        }
        robots
      }
      typeOfForm
      hubspotFormId
      image {
        gatsbyImageData(layout: FULL_WIDTH)
        url
      }
      secondaryImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
